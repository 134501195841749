<template>
	<div class="error">
		<div class="error-con">
			<img class="error-tu" :src="imgUrl+'9fe99bf296c4468783afb42f213310fa'" />
			<p>您访问的页面不存在...</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "error",
		data() {
			return {
				imgUrl: this.imgUrl,
			};
		},
		methods: {

		},
	};
</script>
<style lang="less">
	.error {
		width: 1100px;
		margin: 0 auto;
		padding: 80px 0 184px;
		
		.error-con {
			padding-top: 100px;
			text-align: center;

			img {
				width: 500px;
				height: 200px;
				display: inline-block;
			}

			p {
				display: block;
				padding: 15px 0;
				font-size: 18px;
			}
		}
	}
</style>
